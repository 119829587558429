<template>
  
  <div v-if="props.user && props.week?.text" class="week_comment">

    <div class="divider">

      <div class="title">
        {{ $t('week_view_commented_by') }} 
      </div> 

      <NuxtLink class="label_author" :to="props.user.link">
        <img 
          :src="props.user.avatar_little"      
          class="user_avatar" 
          :alt="props.user.name" 
          >
        {{ props.user.name }}
      </NuxtLink>

      <div class="detail">
          {{ $dayjs.utc(props.week.add_date).fromNow() }}          
        </div>

    </div>


    <div class="text" ref="refWeekText">{{ props.week.text }} </div>

    
    <GeneralTranslateButton
      v-if="props.week.text && refWeekText"
      :el="refWeekText"
      :text="props.week.text"
      :id="props.week.id"
      :type="'week'"
      :locale="$i18n.locale"
      />
  
  </div>
  
</template>

<script setup>
  
const props = defineProps({
  week: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true
  }
})
const refWeekText = ref(null);

</script>




<style scoped>
 

.label_author{  
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.label_author .detail{
margin-left: 6px;
}

.label_author .user_avatar{
  border-radius: 50px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.text{
  white-space: pre-wrap;
  margin-bottom: 0.5rem;
}

@container pb (max-width: 768px) {

  .divider{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .divider .title{
    width: 100%;
  }

  .divider::before, .divider::after{
    display:none;
  }
}
</style>
